import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, TextField } from '@mui/material';
import logoImage from './image/logo_dark_rewards.png';


function App() {
  return (
    <div style={{ backgroundColor: '#0C1115', color: '#FFFFFF', minHeight: '100vh' }}> {/* Set the background color and text color */}
      <Container maxWidth="sm" sx={{ textAlign: 'left', py: '20vh' }}>
        {/* Logo Placeholder */}
        <Box sx={{ width: '100%', mb: 6 }}>          
          <img src={logoImage} alt="Alloy Rewards Logo" style={{ maxWidth: '50%', height: 'auto' }} />
        </Box>

        <Typography variant="h2" gutterBottom sx={{ color: '#FFFFFF', mb: 6}}>
          Turn healthcare spends into rewards.
        </Typography>
        <Typography variant="h5" sx={{ mb: 4, color: '#FFFFFF' }}>
          <b>Coming soon - </b> the loyalty program that rewards you for everything healthcare.
        </Typography>

      </Container>
    </div>
  );
}



export default App;

